<template>
    <div class="page">
        <top ref="top" :login="login"></top>

        <div class="content" style="overflow: visible;">
            <div class="product">

                <div class="product-desc">
                    <div class="image">
                        <el-image v-if="image" class="main-img" :src="image" fit="contain"></el-image>
                        <div class="row-img">
                            <div class="img" v-for="(item, index ) in item.ImageList" :key="index" @click="checkImage(item, index)">
                                <el-image :class="imageIndex == index ? 'click-img' : ''" :src="item" fit="contain"></el-image>
                            </div>
                        </div>
                    </div>
                    <div class="product-content">
                        <div class="country">
                            <div class="product-left">
                                <span class="discount">{{ item.Discount }}</span>
                                <img v-if="item.Country" :src="require(`@/assets/images/flag-${item.Country}.png`)" alt="" style="border: 1px solid #ccc;">
                                <span v-if="item.CountryDesc"> {{ item.CountryDesc }}-{{ item.WarehouseTypeDesc }}-{{ item.WarehouseType == 1 ? '包邮' : '自提' }}</span>
                                <span style="margin-left: 20px;">{{ item.Name }}</span>
                            </div>
                        </div>
                        <div class="product-price">
                            <span>{{ item.PriceDesc }} </span>
                            <span class="source">{{ item.OriginalPriceDesc }}</span>
                        </div>
                        <div class="product-store">
                            <div>
                                <span class="title">库存</span><span>{{ item.Stock }}</span>
                            </div>
                            <div>
                                <span class="title">起订数</span><span>{{ item.MinOrder }}</span>
                            </div>
                            <div class="adress" v-if="item.WarehouseType == 2 && item.WareHouse">
                                <span class="title">地址</span><span class="adress-desc">{{ item.WareHouse }}</span>
                            </div>
                        </div>
                        <div class="btn">
                            <div>
                                <a :href="item.Url" target="_blank" style="text-decoration: none;color:#ffffff"> <el-button class="link"> {{ item.IsCompetitorUrl ? "竞品链接" : "直达链接" }}&gt;</el-button></a>
                                <el-button class="bid" :class="item.CanBuy ? '' : 'bidding'" :disabled="!item.CanBuy" @click="onBidClick(item)"><i class="iconfont icon-lightning1"></i>{{ item.IsSellPrice ? "立即购买" : "快速竞价" }}</el-button>
                            </div>
                            <button class="share-btn">
                                <span class="text">分享 <i class="el-icon-share"></i></span>
                                <div class="btns">
                                    <!-- <a href="#"><i><svg t="1706074305892" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1212" width="20" height="20">
                                                <path d="M692.699238 336.887706c11.619123 0 23.117414 0.831898 34.517504 2.108006C696.19497 194.549965 541.769728 87.227597 365.488742 87.227597 168.405197 87.227597 6.977229 221.535539 6.977229 392.107418c0 98.493235 53.707366 179.306803 143.459123 242.033357l-35.857101 107.840102 125.329408-62.837146c44.84311 8.861798 80.827085 18.002022 125.578138 18.002022 11.250688 0 22.40215-0.561766 33.469645-1.428582-7.001702-23.95351-11.06647-49.054208-11.06647-75.120947C387.891917 463.976243 522.3936 336.887706 692.699238 336.887706zM497.405542 232.406118c30.611456 0 55.425536 24.815206 55.425536 55.427379s-24.814182 55.426355-55.425536 55.426355c-30.613504 0-55.427584-24.815206-55.427584-55.426355S466.794086 232.406118 497.405542 232.406118zM246.567526 344.377344c-30.611456 0-55.427584-24.815206-55.427584-55.426355 0-30.611149 24.81623-55.426355 55.427584-55.426355 30.613504 0 55.428608 24.815206 55.428608 55.426355C301.996134 319.561114 277.18103 344.377344 246.567526 344.377344zM1017.379942 617.455821c0-143.330406-143.423283-260.165325-304.515686-260.165325-170.58089 0-304.924979 116.834918-304.924979 260.165325 0 143.57801 134.34409 260.158157 304.924979 260.158157 35.697459 0 71.712154-9.0112 107.569254-17.99895l98.340659 53.861683-26.969293-89.592525C963.769856 769.897677 1017.379942 698.309222 1017.379942 617.455821zM619.184947 577.275699c-21.799322 0-39.469466-17.673523-39.469466-39.471002 0-21.799526 17.671168-39.468954 39.469466-39.468954s39.469466 17.670451 39.469466 39.468954C658.656563 559.6032 640.983347 577.275699 619.184947 577.275699zM816.270541 579.514675c-21.80137 0-39.47049-17.672499-39.47049-39.468954 0-21.80055 17.670144-39.468954 39.47049-39.468954 21.798298 0 39.469466 17.669427 39.469466 39.468954C855.741133 561.842176 838.068941 579.514675 816.270541 579.514675z" fill="#FFFFFF" p-id="1213"></path>
                                            </svg></i></a> -->
                                    <a @click="openFacebook"><i><svg t="1706074625196" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1616" width="20" height="20">
                                                <path d="M1024 512c0-282.763636-229.236364-512-512-512C229.236364 0 0 229.236364 0 512s229.236364 512 512 512C794.763636 1024 1024 794.763636 1024 512zM374.504727 512 374.504727 414.021818l60.043636 0L434.548364 354.769455c0-79.918545 23.877818-137.495273 111.383273-137.495273l104.075636 0 0 97.745455-73.262545 0c-36.724364 0-45.056 24.389818-45.056 49.943273l0 49.058909 112.919273 0L629.201455 512l-97.512727 0 0 295.517091L434.548364 807.517091 434.548364 512 374.504727 512z" p-id="1617" fill="#FFFFFF"></path>
                                            </svg></i></a>
                                    <!-- <a href="#"><i><svg t="1706074700904" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1867" width="20" height="20">
                                                <path d="M332.8 85.333333h358.4C827.733333 85.333333 938.666667 196.266667 938.666667 332.8v358.4a247.466667 247.466667 0 0 1-247.466667 247.466667H332.8C196.266667 938.666667 85.333333 827.733333 85.333333 691.2V332.8A247.466667 247.466667 0 0 1 332.8 85.333333m-8.533333 85.333334A153.6 153.6 0 0 0 170.666667 324.266667v375.466666C170.666667 784.64 239.36 853.333333 324.266667 853.333333h375.466666a153.6 153.6 0 0 0 153.6-153.6V324.266667C853.333333 239.36 784.64 170.666667 699.733333 170.666667H324.266667m411.733333 64a53.333333 53.333333 0 0 1 53.333333 53.333333A53.333333 53.333333 0 0 1 736 341.333333 53.333333 53.333333 0 0 1 682.666667 288a53.333333 53.333333 0 0 1 53.333333-53.333333M512 298.666667a213.333333 213.333333 0 0 1 213.333333 213.333333 213.333333 213.333333 0 0 1-213.333333 213.333333 213.333333 213.333333 0 0 1-213.333333-213.333333 213.333333 213.333333 0 0 1 213.333333-213.333333m0 85.333333a128 128 0 0 0-128 128 128 128 0 0 0 128 128 128 128 0 0 0 128-128 128 128 0 0 0-128-128z" fill="#FFFFFF" p-id="1868"></path>
                                            </svg></i></a>
                                    <a href="#"><i><svg t="1706074754343" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2112" width="20" height="20">
                                                <path d="M787.456 442.368h-18.944v41.984h27.136V450.56c0-4.608-3.584-8.192-8.192-8.192zM512 0z" fill="#FFFFFF" p-id="2113"></path>
                                                <path d="M512 0C229.376 0 0.512 229.376 0.512 512S229.376 1024 512 1024s511.488-228.864 511.488-512c0-282.624-228.864-512-511.488-512z m342.528 414.72c0-14.336 11.776-26.112 26.112-26.112s26.112 11.776 26.112 26.112-11.776 26.112-26.112 26.112h-26.112V414.72zM394.24 460.8l44.032-88.576h51.712l-36.864 70.656c-1.536 3.584 0.512 7.168 4.608 7.168h3.072l3.072-6.144h51.712l-44.544 87.04c-1.536 3.584 0.512 7.168 4.608 7.168h29.696l-23.552 43.008H421.888c-25.6-1.024-9.728-32.256-9.728-32.256l27.136-55.808h-35.84C378.368 492.032 394.24 460.8 394.24 460.8z m-248.32 162.816l-29.696-54.272s17.408-54.272 12.8-125.952H184.32s0.512 163.328-38.4 180.224z m126.976-11.776s-1.536 40.448-36.864 40.448h-17.408s-34.304-3.584-34.304-44.032h34.816V371.712h53.76v240.128z m34.816-168.448h54.784c-4.608 71.68 12.8 125.952 12.8 125.952l-29.696 54.272c-38.4-16.896-37.888-180.224-37.888-180.224z m79.872 208.896c-9.216 0-13.312-6.144-13.312-6.144l26.624-51.2c4.608 4.608 10.752 3.584 10.752 3.584h70.144l-28.16 53.76H387.584z m275.968-0.512H481.792l25.6-51.712h51.2V443.392h-34.304V388.608h121.856v54.784h-33.792v156.672h51.712v51.712z m198.656 0h-13.824c-25.088 0-45.056-19.968-45.056-45.056h50.176v-56.32c0-7.168-5.632-12.8-12.8-12.8h-72.192v114.176h-52.736V537.6h-52.736V483.84h52.736v-41.984h-33.28V388.608h33.28v-16.896h52.736v16.896h20.48c33.28 0 59.904 26.624 59.904 59.904v35.328h6.144c28.672 0 51.712 23.04 51.712 51.712v71.168c0.512 25.088-19.456 45.056-44.544 45.056z" fill="#FFFFFF" p-id="2114"></path>
                                            </svg></i></a> -->
                                </div>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="desc">
                <div class="desc-title">
                    <p>商品介绍</p>
                </div>
                <div class="desc-content" ref="descContent" :style="{ 'max-height': isMore ? 'none' : '330px' }">
                    <div v-if="item.Detail" ref="productDetail" class="product-detail" v-html="item.Detail"> </div>
                    <span v-else style="font-style: oblique;  color: #999;"> 这个商品没有上传更多信息</span>
                </div>
                <span v-if="item.Detail && showProductDetail" class="more" @click="isMore = !isMore"> {{ isMore ? '-收起更多' : '-更多' }} </span>
            </div>
            <div class="desc rule">
                <div class="desc-title">
                    <p>交易规则</p>
                </div>
                <div class="desc-content" :style="{ height: isRule ? 'initial' : '330px' }">
                    <div v-html="ruleContent"></div>
                    <div style="margin-top: 26px;">
                        <p style="margin: 0px;  padding: 0px;  box-sizing: border-box;font-weight: bolder;  font-family: 宋体;">9、交易流程</p>
                        <img :src="require('@/assets/images/flow.png')" alt="" style="width: 100% !important;  object-fit: contain;">
                    </div>
                </div>
                <span class="more" @click="isRule = !isRule"> {{ isRule ? '-收起更多' : '-更多' }} </span>
            </div>
        </div>
        <div class="beian">
            <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2023009150号</a>
        </div>
    </div>
</template>

<script>
import Util from "@/utils/util";
import Service from "@/utils/service";
import LoginDialog from "@/views/Component/LoginDialog.vue"
import BidDialog from "./BidDialog.vue";
import PayDialog from "./PayDialog.vue";
import CustomerService from '@/views/index/CustomerService'
import top from '@/views/Component/top.vue'
export default {
    props: [],
    components: { top },
    data() {
        return {
            item: '',
            isMore: false,
            isRule: false,
            imageIndex: 0,
            showProductDetail: false,
            ruleContent: '',
            image: '',
        }
    },
    methods: {
        openFacebook() {
            var Url = `https://www.qingcang1.com/api/share/product?id=${this.item.Id}`;
            window.open(`https://www.facebook.com/sharer.php?&u=${encodeURIComponent(Url)}&share=1&source=null`);
        },
        login() {
            var dialog = Util.createComponent(LoginDialog, {
            }, document.querySelector('#app'));
            dialog.open();
        },

        async onBidClick(item) {
            if (!this.$store.state.loginUserId) {
                this.login();
                return;
            } else {
                item.Image = item.ImageList[0];
                if (item.IsSellPrice) {
                    var payDialog = Util.createComponent(
                        PayDialog,
                        {},
                        document.querySelector("#app")
                    );
                    payDialog.open(item);
                } else {
                    var dialog = Util.createComponent(
                        BidDialog,
                        {},
                        document.querySelector("#app")
                    );
                    dialog.open(item);
                }
            }
            let subscribeResult = await Service.checkSubscribe();
            if (!subscribeResult.Subscribe) {
                this.login();
                return;
            }
        },

        async getProductDetail(id) {
            let result = await Service.getHomeProductDetail({ id: id });
            this.item = result;
            if (!this.$store.state.loginUserId) {
                this.item.CanBuy = true;
            }
            this.checkImage(result.ImageList[0], 0);
            let noticeDetail = await Service.getNoticeDetail({
                id: 19
            });
            this.ruleContent = noticeDetail.Content;
            if (this.item.Detail) {
                this.observeProductDetail();
            }
        },

        checkImage(url, index) {
            this.image = url;
            this.imageIndex = index;
        },

        customerService() {
            var dialog = Util.createComponent(CustomerService, {
            }, document.querySelector('#app'));
            dialog.open();
        },

        setTitle() {
            let item = this.item;
            let title = `${item.Discount}  ${item.CountryDesc}-${item.WarehouseTypeDesc}-${item.WarehouseType == 1 ? "包邮" : "自提"} ${item.Name}`;
            if (item.Name) {
                if (document.title != title) {
                    document.title = title;
                }
            }
        },

        observeProductDetail() {
            let productNode = this.$refs.descContent;
            let options = {
                childList: true,
            };

            let productDetail = this.$refs.productDetail;
            let observeImage = false;

            if (productDetail) {
                observeImage = this.observeDetailImage();
            }

            if (!observeImage) {
                let observer = new MutationObserver((mutationRecoards, observer) => {
                    this.observeDetailImage();
                });
                // 对观察者添加需要观察的元素，并设置需要观察元素的哪些方面
                observer.observe(productNode, options);
            }
        },

        observeDetailImage() {
            let productNode = this.$refs.descContent;
            let productDetail = this.$refs.productDetail;
            if (!productDetail) {
                return false;
            }

            if (productDetail.clientHeight > productNode.clientHeight) {
                this.showProductDetail = true;
                return true;
            }

            let imgNodeList = document.querySelectorAll(".product-detail img");
            if (imgNodeList.length > 0) {
                for (let i = 0; i < imgNodeList.length; i++) {
                    imgNodeList[i].onload = () => {
                        if (this.showProductDetail) {
                            return;
                        }
                        if (productDetail.clientHeight > productNode.clientHeight) {
                            this.showProductDetail = true;
                        } else {
                            this.showProductDetail = false;
                        }
                    };
                }
                return true;
            } else {
                this.showProductDetail = false;
            }

            return false;
        },
    },
    computed: {
    },
    watch: {
        item(val) {
            if (val) {
                this.setTitle();
            }
        },
    },

    mounted() {
        this.oldTitle = document.title;
        this.getProductDetail(this.$route.query.id);

        let translateParent = document.querySelector("#translateGoogle");
        let translateGoogle = document.querySelector("#google_translate_element");
        if (translateGoogle) {
            translateParent.appendChild(translateGoogle);
        }
    },
    destroyed() {
        document.title = this.oldTitle;
    },
}
</script>


<style lang="scss" scoped>
.translate {
    width: 120px;
    margin-right: 30px;
}

.page {
    // display: flex;
    // flex-direction: column;
    height: 95vh;
}

.header {
    position: relative !important;
    top: 0 !important;
}

::v-deep {

    .content {
        overflow: auto;
        flex: 1;
        margin: 0 auto;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .product {
            width: 1200px;
            margin-top: 10px;

            .product-desc {
                display: flex;
                margin-top: 54px;

                .image {
                    width: 500px;
                    height: 540px;
                    margin-right: 20px;


                    .main-img {
                        width: 450px !important;
                        height: 450px;
                        position: unset;
                    }

                    .row-img {
                        margin-top: 10px;
                        width: 450px;
                        overflow: auto;
                        display: flex;
                        list-style-type: none;
                        justify-content: center;

                        .img {
                            height: 90px;
                            margin: 5px 5px;
                            cursor: pointer;

                            .el-image {
                                border: 2px solid transparent;
                                width: 70px !important;
                                height: 70px;
                                padding: 2px;
                                position: unset;
                            }

                            .click-img {
                                border-color: #009dff;
                                box-shadow: 0 0px 3px 4px #ddf2ff, inset 2px 2px 2px 2px #FFF;
                                border-radius: 4px;

                                img {
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                }
            }

            .product-content {
                flex: 1;
                margin-left: 30px;

                .country {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    color: #000;
                    font-size: 24px;
                    overflow: hidden;

                    .product-left {
                        display: inline-block;
                        margin-right: 20px;

                        .discount {
                            display: inline-block;
                            width: 55px;
                            height: 27px;
                            font-size: 17px;
                            background: #ea3a3c;
                            border-radius: 2px;
                            color: #ffffff;
                            line-height: 27px;
                            text-align: center;
                            vertical-align: bottom;
                        }

                        img {
                            margin: 0 4px;
                            width: 35px;
                            height: 27px;
                            vertical-align: bottom;
                        }
                    }
                }

                .product-price {
                    text-align: left;
                    margin-top: 30px;
                    border-top: 1px dashed #e5e5e5;
                    padding-top: 20px;

                    span {
                        color: #f44c44;
                        font-size: 24px;
                        font-weight: 600;
                        margin-left: 0;
                        margin-right: 14px;
                    }

                    .source {
                        background-color: #fafafa;
                        color: #999;
                        font-size: 14px;
                        text-decoration: line-through;
                        font-weight: 400;
                    }
                }

                .product-store {
                    text-align: left;
                    margin-top: 60px;

                    div {
                        margin-top: 20px;
                    }

                    span {
                        font-size: 16px;
                        margin-left: 0;
                        margin-right: 14px;
                    }

                    .adress {
                        display: flex;

                        .title {
                            display: inline-block;
                            width: 70px;
                        }

                        .adress-desc {
                            flex: 1;
                            display: inline-block;
                        }
                    }

                    .title {
                        display: inline-block;
                        width: 70px;
                    }

                }

                .btn {
                    display: flex;
                    margin-top: 80px;

                    button {
                        width: 120px;
                        height: 40px;
                        background: #009dff;
                        border: 1px solid rgba(0, 157, 255, 0.30);
                        border-radius: 2px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #ffffff;
                    }

                    .bid {
                        margin-left: 30px;
                        background: #ea3a3c;
                        border: 0;
                        color: #ffffff;
                    }

                    .bidding {
                        background: #9999 !important;
                    }

                    .share-btn {
                        margin-left: 20px;
                        width: 120px;
                        background: #009dff;
                        color: #fff;
                        border: 0;
                        font-size: 16px;
                        font-weight: 500;
                        cursor: pointer;
                        overflow: hidden;
                        position: relative;
                        transition: .3s;
                    }

                    .btns {
                        position: absolute;
                        width: 70%;
                        display: flex;
                        justify-content: space-around;
                        bottom: 0;
                        transform: translateY(30px);
                        left: 15px;
                    }

                    .share-btn:hover .text,
                    .share-btn:hover .share {
                        transform: translateY(-40px);
                    }

                    .share-btn:hover .btns a {
                        transform: translateY(-35px);
                    }



                    .btns a,
                    .text,
                    .share {
                        color: #fff;
                        transition: .3s;
                    }

                    .text {
                        display: inline-block;
                    }

                    .share {
                        transition-delay: 60ms;
                    }

                    .btns a:nth-child(2) {
                        transition-delay: 60ms;
                    }

                    .btns a:nth-child(3) {
                        transition-delay: 120ms;
                    }

                    .btns i:hover {
                        opacity: .7;
                    }

                }
            }
        }

        .desc {
            width: 1200px;
            margin-top: 30px;
            color: #000;
            font-size: 12px;
            line-height: 2;
            border-top: 1px dashed #e5e5e5;

            .desc-title {
                font-weight: 600;
                font-size: 16px;
            }

            .desc-content {
                width: 800px;
                margin: 0 auto;
                padding-left: 10px;
                overflow: hidden;

                .product-detail {
                    overflow: hidden;

                    word-break: break-all;

                    img {
                        width: 100% !important;
                        object-fit: contain;
                        vertical-align: middle;
                    }

                    .home {
                        .foot {
                            .write {
                                width: auto;
                            }
                        }
                    }
                }
            }

            .more {
                color: #000;
                font-weight: 600;
                display: block;
                text-decoration: none;
                cursor: pointer;
                font-size: 16px;
            }
        }

        .rule {
            margin-top: 20px;
            border-top: 0;
            margin-bottom: 40px;
        }
    }

    .beian {
        width: 100%;
        padding: 5px 0;
        background: #282828;
        text-align: center;
        font-size: 16px;
        z-index: 1;

        a {
            color: rgba(255, 255, 255, 0.3);
        }
    }
}
</style>
