import axios from 'axios'
import qs from 'qs' // qs这个包在install  axios 时就已经装了，因为 qs 是 axios 的依赖包
import { Loading, Message } from 'element-ui'
import Util from '@/utils/util';
import router from '@/router'

axios.defaults.withCredentials = false // cookie
axios.defaults.timeout = 60000
axios.loadingRequstCount = 0
axios.loadingInstance = null
axios.loadingCount = 0

axios.interceptors.request.use(
  config => {
    if (config.showLoading) {
      axios.loadingRequstCount++

      axios.loadingInstance = Loading.service({
        lock: true,
        background: 'hsla(0,0%,100%,.8)',
      })
    }

    axios.loadingCount++;

    let token = Util.getToken();
    if (token) {
      config.headers['X-Client-Token'] = token;
    }

    return config
  },
  error => {
    if (error?.config?.showLoading) {
      if (axios.loadingRequstCount > 0) {
        axios.loadingRequstCount--
      }

      if (axios.loadingRequstCount <= 0) {
        axios.loadingInstance?.close();
      }
    }

    return Promise.reject(error)
  }
)

axios.interceptors.response.use(response => {
  if (response?.config?.showLoading) {
    if (axios.loadingRequstCount > 0) {
      axios.loadingRequstCount--
    }

    if (axios.loadingRequstCount <= 0) {
      axios.loadingInstance?.close();
    }
  }

  if (response.data.code == 0) {
    let token = response.headers['X-Client-Token'] || response.headers['x-client-token'];
    if (token) {
      Util.setToken(token);
    }
    return response.data.data
  }
  else {
    if (response.data.code == 4) {
      Message.error('登录过期，请重新登录');
      Util.removeToken();
      router.push("/")
    }

    else {
      Message.error(response.data.msg)
    }
    return Promise.reject(response);
  }
}, error => {
  if (error?.config?.showLoading) {
    if (axios.loadingRequstCount > 0) {
      axios.loadingRequstCount--
    }

    if (axios.loadingRequstCount <= 0) {
      axios.loadingInstance?.close();
    }
  }

  Message.error('请求错误')
  return Promise.reject(error);
})

axios.get = function (url, params, showLoading) {
  return axios({
    method: 'GET',
    url,
    params,
    showLoading,
  })
}

axios.post = function (url, data, showLoading) {
  return axios({
    method: 'POST',
    url: url,
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    showLoading,
  })
}

axios.postJson = function (url, data, showLoading) {
  return axios({
    method: 'POST',
    url: url,
    data,
    headers: {
      'Content-Type': 'application/json'
    },
    showLoading,
  })
}

axios.postForm = function (url, data, showLoading) {
  return axios({
    method: 'POST',
    url: url,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    showLoading,
  })
}

export default axios
