import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "@/layouts/MainLayout";
import NoticeList from "@/views/notice/NoticeList.vue";
import NoticeDetail from "@/views/notice/NoticeDetail.vue";
import EmptyLayout from "@/layouts/EmptyLayout";
import Util from "@/utils/util";
import store from "@/store";
import Home from "@/views/index/Home.vue";
import Agreement from "@/views/agreement/Agreement";
import ProductDetail from "@/views/index/ProductDetail.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/productDetail",
    component: ProductDetail,
  },
  {
    path: "/agreement",
    component: Agreement,
  },

  {
    path: "/index",
    component: Home,
    children: [
      {
        path: "/",
        component: () => import("@/views/index/Home"),
      },
    ],
  },
  {
    path: "/contact",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/contact/Contact"),
      },
      {
        path: "EditContact",
        component: () => import("@/views/contact/EditContact"),
      },
    ],
  },
  {
    path: "/orderList",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/order/OrderList"),
      },
      {
        path: "orderDetail",
        component: () => import("@/views/order/OrderDetail"),
      },
      {
        path: "payOrder",
        component: () => import("@/views/order/PayOrder"),
      },
      {
        path: "shipOrder",
        component: () => import("@/views/order/ShipOrder"),
      },
      {
        path: "",
        component: MainLayout,
      },
    ],
  },

  {
    path: "/productList",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/product/ProductList"),
      },
      {
        path: "editProduct",
        component: () => import("@/views/product/EditProduct"),
      },
      {
        path: "addProductSuccess",
        component: () => import("@/views/product/AddProductSuccess"),
      },
    ],
  },
  {
    path: "/biddingList",
    component: MainLayout,
    children: [
      {
        path: "/",
        component: () => import("@/views/bidding/BiddingList"),
      },
      {
        path: "refuseDialog",
        component: () => import("@/views/bidding/RefuseDialog"),
      },
    ],
  },
  {
    path: "/noticeList",
    component: NoticeList,
    children: [
      {
        path: "/",
        component: () => import("@/views/notice/NoticeList"),
      },
      // {
      //   path: "noticeDetail",
      //   component: () => import("@/views/notice/NoticeDetail"),
      // },
    ],
  },
  {
    path: "/noticeDetail",
    component: () => import("@/views/notice/NoticeDetail"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {

//   if (to.meta?.anonymous) {
//     next()
//   } else {
//     if (Util.getToken()) {
//       next()
//     }
//     else {
//       next('/login')
//     }
//   }
// })

router.afterEach((to, from) => {
  if (to.matched && to.matched.length > 2) {
    for (let i = 0; i < to.matched.length; i++) {
      if (to.matched[i].components.default.name === "EmptyLayout") {
        to.matched.splice(i, 1);
        i--;
      }
    }
  }

  var target = store.state.visitedRoutes.find((v) => v.path == to.path);
  if (target && to.matched?.length > 0) {
    target.name = to.matched[to.matched.length - 1].components.default.name;
  }
});

export default router;
