<template>
  <el-scrollbar class="side-bar-container">
    <logo />
    <el-menu @select="handleSelect" :background-color="variables['menu-background']" :text-color="variables['menu-color']" :active-text-color="variables['menu-color-active']" :default-active="defaultActive" :collapse="false" :collapse-transition="true" :default-openeds="defaultOpeneds" :unique-opened="uniqueOpened" mode="vertical">
      <side-bar-item v-for="item in menu" :key="item.path" :path="item.path" :item="item" />
    </el-menu>
    <customer-Service />
  </el-scrollbar>
</template>
<script>
import variables from "@/assets/css/variables.module.scss";
import Util from "@/utils/util";

export default {
  name: "SideBar",
  data() {
    return {
      uniqueOpened: true,
      defaultOpeneds: [],
    };
  },
  computed: {
    menu() {
      return this.$store.state.menu;
    },
    defaultActive() {
      let path = this.$route.fullPath;
      let target = this.$store.state.components.find(v => (!Array.isArray(v.path) && path.startsWith(v.path)) || (Array.isArray(v.path) && v.path.some(v => path.startsWith(v))));

      if (target) {
        return Array.isArray(target.path) ? target.path[0] : target.path
      }

      return '';
    },
    variables() {
      return variables;
    },
  },
  mounted() {
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if (key == '/exit') {
        this.$router.push('/')
        Util.removeToken();
        this.$store.commit("setLoginUserId",'')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin active {
  &:hover {
    color: #0187fb;
    background-color: #e6f7ff !important;
  }

  &.is-active {
    color: #0187fb;
    background-color: #e6f7ff !important;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 3px;
      height: 100%;
      background-color: #0187fb;
      z-index: 1;
    }
  }
}

.side-bar-container {
  min-width: 220px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  transition: width 0.3s;



  ::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .el-menu-item [class^="iconfont"],
    .el-submenu [class^="iconfont"] {
      vertical-align: middle;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 21px;
    }

    .el-menu-item {
      .dot {
        margin-left: 30px !important;
      }
    }



    .el-menu {
      position: relative;
      border: 0;



      .el-submenu {
        &.is-active {
          .el-submenu__title {
            font-weight: 500 !important;
            color: #0187fb !important;

            .el-icon-prefix {
              color: #0187fb !important;

            }
          }

        }
      }

    }

    .el-menu-item,
    .el-submenu__title {

      height: 50px;
      overflow: hidden;
      line-height: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      font-size: 18px;
      padding-left: 50px !important;

      &:hover {
        color: #0187fb !important;
        background: #e6f7ff !important;

      }


    }

    .el-menu-item {
      @include active;
      color: #0187fb;
      background: #fff !important;
      padding-left: 50px !important;
    }
  }
}
</style>
