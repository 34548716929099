<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";

export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    async getAccountInfo() {
      let result = null;

      // try {
      result = await Service.getAccountInfo();
      // } catch {
      //   this.$store.commit("reset");
      //   return;
      // }

      this.$store.commit("reset");
      this.$store.commit("setAccountInfo", result.Name);
      this.$store.commit("initMenu");
      // if (this.$route.path == "/") {
      //   let search = (menu) => {
      //     let target = "";
      //     for (let i = 0; i < menu.length; i++) {
      //       if (menu[i]?.children) {
      //         target = search(menu[i].children);
      //       } else {
      //         target = menu[i].path;
      //         break;
      //       }
      //     }
      //     return target;
      //   };
      //   if (this.$store.state.menu.length > 0) {
      //     this.$router.push(search(this.$store.state.menu));
      //   }
      // } else {
      this.$store.commit("addVisitedRoute", this.$route);
      // }
    },

    setBodyObserver() {
      let observerOptions = {
        childList: true, // 观察目标子节点的变化，是否有添加或者删除
        attributes: true, // 观察属性变动
        subtree: true // 观察后代节点，默认为 false
      }
      let bodyNode = document.querySelector("body");
      let bodyObserver = new MutationObserver((mutationList, observer) => {

        if (document.querySelector('body > .skiptranslate:first-child')) {
          bodyNode.style.height = 'calc(100vh - 40px)';
          this.$store.commit("setHasTranslate", true)
          observer.disconnect();
          this.setTranslateObserver();
        } else {
          bodyNode.style.height = '100vh';
          this.$store.commit("setHasTranslate", false)
        }
      });
      bodyObserver.observe(bodyNode, observerOptions);
    },
    setTranslateObserver() {
      let observerOptions = {
        childList: true, // 观察目标子节点的变化，是否有添加或者删除
        attributes: true, // 观察属性变动
        subtree: true // 观察后代节点，默认为 false
      }
      let bodyNode = document.querySelector("body");
      let googleNode = document.querySelector("body > .skiptranslate:first-child");
      let googleObserver = new MutationObserver((mutationList, observer) => {
        if (googleNode.style.display == 'none') {
          this.$store.commit("setHasTranslate", false)
          bodyNode.style.height = '100vh';
        } else {
          this.$store.commit("setHasTranslate", true)
          bodyNode.style.height = 'calc(100vh - 40px)';
        }
      });
      googleObserver.observe(googleNode, observerOptions);
    }
  },
  async mounted() {
    this.setBodyObserver();
    if (Util.getToken()) {
      let result = await Service.getHome()
      this.$store.commit("setLoginUserId", result.Id);
    }
  },
  created() {
    this.$store.commit("initMenu");

    // if (Util.getToken()) {
    //   this.getAccountInfo();
    // }
  },

  watch: {
    // $route: {
    // handler(to, from) {
    //   if (from?.path == "/login" && !to.meta.anonymous) {
    //     this.getAccountInfo();
    //   }
    // },
    // immediate: true,
    // },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

::v-deep {
  .el-message-box__wrapper {

    .el-message-box {
      border-radius: 10px;
    }
  }
}
</style>
